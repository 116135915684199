const localeMap: Record<string, string> = {
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  de: 'de-DE',
  pt: 'pt-PT',
  ca: 'ca',
  it: 'it-IT'
};
export const currency = (locale = 'en', minimumFractionDigits = 2) =>
  Intl.NumberFormat(localeMap[locale], {
    currency: 'EUR',
    style: 'currency',
    minimumFractionDigits,
    useGrouping: true
  });
export const percent = (locale = 'en') =>
  Intl.NumberFormat(localeMap[locale], {
    maximumFractionDigits: 3,
    style: 'percent',
    useGrouping: true,
    minimumFractionDigits: 0
  });
