import React, {SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {currency} from 'utils/formatters';
import {useI18next} from 'gatsby-plugin-react-i18next';

const Input = styled.input`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-right: 10px;
  line-height: 36px;
  outline: none;
  color: hsla(0, 0%, 10%, 0.9);
  border: 0;
  text-align: right;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
  background: #fff;
  appearance: none;

  &:focus,
  &:active {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(50, 151, 211, 0.3);
  }
  @media (max-width: 600px) {
    margin-top: 6px;
  }
`;

type Props = {
  onValueChange: (value: number) => void;
};

export const CurrencyInput: React.FC<Props> = ({onValueChange}) => {
  const {language} = useI18next();
  const [amount, setAmount] = useState<number | ''>(200);
  const [maskedAmount, setMaskedAmount] = useState<number | string>(currency(language).format(200));

  const createMask = (value: number) => {
    if (!value) return;
    return currency(language, 0).format(value);
  };

  useEffect(() => {
    onValueChange(amount || 0);
  }, [amount]);

  return (
    <>
      <input style={{display: 'none'}} name="amount" value={amount} />
      <Input
        name="maskedAmount"
        type="text"
        value={maskedAmount}
        onFocus={() => setMaskedAmount('')}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onValueChange(amount || 0);
          }
        }}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          const value = ev.target.value.replace(/\.|,|€|\$|\s/g, '');
          setAmount(value as SetStateAction<number | ''>);
          setMaskedAmount(createMask(Number(value)) as SetStateAction<number | string>);
        }}
      />
    </>
  );
};
