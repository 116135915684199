import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import QuestionCircleFilled from 'images/question_filled_circle.svg';
import ReactTooltip, {TooltipProps} from 'react-tooltip';
import {Flex} from './Flex';
import './css/hint.css';

interface IProps extends TooltipProps {
  id: string;
  message: string | JSX.Element;
  title?: string | JSX.Element;
}

const HintIcon = styled.img`
  opacity: 0.3;
  width: 15px;
`;

export const Hint: React.FC<IProps> = ({message, title, ...props}) => {
  if (!message) return null;
  return (
    <>
      <ReactTooltip aria-haspopup="true" id={props.id} backgroundColor="#000" textColor="#fff">
        {message}
      </ReactTooltip>
      <div data-tip {...props} data-for={props.id} className="hint">
        {title ? (
          <Flex alignItems="center">
            <span style={{marginRight: '5px'}}>{title}</span>
            <HintIcon src={QuestionCircleFilled} />
          </Flex>
        ) : (
          <HintIcon src={QuestionCircleFilled} />
        )}
      </div>
    </>
  );
};
