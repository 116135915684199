// i18next-extract-mark-ns-start interchange-fee-calculator

import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Flex} from 'components/Flex';
import {Hint} from 'components/Hint';
import {Bold} from 'components/StickyBanner';
import {SignUpButton} from 'components/SignUpButton';
import {AnchorLink} from 'components/AnchorLink';
import SmallText from 'components/SmallText';
import rocket from 'images/rocket.svg';
import {Spacer} from 'components/Spacer';
import {StyledDiv} from 'components/StyledSx';
import {containerSize, ScreenSizes} from '../../types/responsive';
import Divider from 'components/Divider';
import {currency} from 'utils/formatters';
import {CurrencyInput} from 'components/CurrencyInput';
import {BlogLink} from 'components/links/Blog';
import {PrismicPage} from 'components/links/PrismicPage';
import {FaqsSection} from 'components/FaqsSection';

const IndexContent = styled.div`
  max-width: 500px;
`;

const List = styled.ul`
  margin: 0 0 30px;
  width: 100%;
`;

const ListItem = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  margin: 0;
  padding: 15px 0;

  p {
    margin: 0 8px 0 0;
    flex: 1;
    text-align: start;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    .hint {
      margin-right: 0;
      margin-left: auto;
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  margin-left: 10px;

  @media (max-width: ${ScreenSizes.xs}) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;

const Select = styled.select`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding-right: 10px;
  line-height: 36px;
  outline: none;
  color: hsla(0, 0%, 10%, 0.9);
  border: 0;
  text-align: right;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
  direction: rtl;
  background: #fff;
  appearance: none;

  &:focus,
  &:active {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(50, 151, 211, 0.3);
  }

  @media (max-width: 600px) {
    margin-top: 6px;
  }
`;

const IndexBackground = styled.div`
  position: relative;
`;

const CalculatorCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex: 1 0 max-content;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 32px 42px;
  max-width: 600px;
  box-shadow: 0 64px 64px rgba(61, 88, 128, 0.05), 0 32px 32px rgba(61, 88, 128, 0.05),
    0 16px 16px rgba(61, 88, 128, 0.05), 0 8px 8px rgba(61, 88, 128, 0.05),
    0 4px 4px rgba(61, 88, 128, 0.05), 0 2px 2px rgba(61, 88, 128, 0.05);

  h4 {
    font-size: 1.3rem;
    margin-bottom: 0.875rem;
    max-width: 12.5rem;
    text-overflow: ellipsis;
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: ${ScreenSizes.md}) {
    padding: 40px 32px;
    margin-top: 16px;
  }

  @media (max-width: ${ScreenSizes.xs}) {
    width: 100%;
  }
`;

const CalculatorForm = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  text-align: start;

  input {
    padding-right: 25px;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .form-container {
      width: 100%;
    }
  }
`;

interface CreditDebitFee {
  average: number;
}

interface TerritorialFee {
  spain: CreditDebitFee;
  eu: CreditDebitFee;
  oeu: CreditDebitFee;
}

interface CardFee {
  visa: TerritorialFee;
  mastercard: TerritorialFee;
}

const FeeListItem = styled.li`
  line-height: 20px;
  margin-bottom: 0;
  text-align: start;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
`;

const RocketContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${containerSize};
  margin: 0 auto;
  padding: 0 20px;
  background-color: white;
`;

const Cta = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const CtaWrapper = styled.div`
  background-image: linear-gradient(-56deg, #12e0c2 0%, #aab4e9 41%);
  border-radius: 16px;
  padding: 80px 98px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const SectionTitle = styled.h2`
  color: #000000;
  font-size: 31px;
  line-height: 38px;
  font-weight: lighter;
  &:after {
    content: '';
    display: block;
    background: white;
    margin-bottom: 1.2rem;
    margin-top: 0.8rem;
    height: 1.5px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    > div {
      display: none;
    }
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const Rocket = styled.img`
  position: absolute;
  bottom: 0;
  right: 100px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  @media (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

interface FeeSelection {
  cardPresence: 'fees';
  card: 'visa' | 'mastercard';
  territory: 'domestic';
  creditOrDebit: 'credit' | 'debit';
}

const initialFeeSelection: FeeSelection = {
  cardPresence: 'fees',
  card: 'visa',
  territory: 'domestic',
  creditOrDebit: 'credit'
};

interface CalculatedFee {
  interchange: number;
  percentage: number;
  base: number;
  provider: number;
  providerName: 'MONEI' | 'Stripe';
}

interface CalculatedFees {
  visaMonei: CalculatedFee;
  mastercardMonei: CalculatedFee;
  visaStripe: CalculatedFee;
  mastercardStripe: CalculatedFee;
}

const FeeTooltip = (calculatedFee: CalculatedFee): JSX.Element => {
  const {language} = useI18next();
  return (
    <>
      <ul style={{listStyle: 'none', marginLeft: 0}}>
        <FeeListItem>
          <div style={{marginRight: '8px'}}>{calculatedFee.providerName} percentage: </div>
          <div>
            {calculatedFee.percentage + '%' + ' + ' + currency(language).format(calculatedFee.base)}
          </div>
        </FeeListItem>
        <FeeListItem>
          <div style={{marginRight: '8px'}}>{calculatedFee.providerName} fee: </div>
          <div>{currency(language).format(calculatedFee.provider)}</div>
        </FeeListItem>
        {calculatedFee.interchange ? (
          <FeeListItem>
            <div style={{marginRight: '8px'}}>Expected Interchange++ fee:</div>
            <div>{currency(language).format(calculatedFee.interchange)}</div>
          </FeeListItem>
        ) : (
          <></>
        )}
      </ul>
    </>
  );
};

const InterchangeFeesPage: React.FC = () => {
  const {t} = useTranslation();
  const {language} = useI18next();
  const [amount, setAmount] = useState<number | ''>(200);
  const [range, setRange] = useState(0.9);
  const [region, setRegion] = useState<string>('spain');
  const [calculatedFees, setCalculatedFees] = useState<CalculatedFees>({
    visaMonei: {interchange: 0, provider: 0, percentage: 0, base: 0, providerName: 'MONEI'},
    mastercardMonei: {interchange: 0, provider: 0, percentage: 0, base: 0, providerName: 'MONEI'},
    visaStripe: {interchange: 0, provider: 3.05, percentage: 0, base: 0, providerName: 'Stripe'},
    mastercardStripe: {
      interchange: 0,
      provider: 3.05,
      percentage: 0,
      base: 0,
      providerName: 'Stripe'
    }
  });

  const [stripeVisaPercentMore, setStripeVisaPercentMore] = useState<string>('0');
  const [stripeMastercardPercentMore, setStripeMastercardPercentMore] = useState<string>('0');
  const [moneiFee, setMoneiFee] = useState({percentage: 0.9, base: 0.24});
  const [fees, setFees] = useState<any>({
    visa: {
      spain: {
        average: 0.35
      },
      eu: {
        average: 0.4
      },
      oeu: {
        average: 2.5
      }
    },
    mastercard: {
      spain: {
        average: 0.4
      },
      eu: {
        average: 0.58
      },
      oeu: {
        average: 2.15
      }
    }
  });

  useEffect(() => {
    if (Number(amount) < 20) {
      setFees({
        visa: {
          spain: {
            average: 1.27
          },
          eu: {
            average: 1.48
          },
          oeu: {
            average: 7.35
          }
        },
        mastercard: {
          spain: {
            average: 0.91
          },
          eu: {
            average: 1.1
          },
          oeu: {
            average: 10.59
          }
        }
      });
    } else {
      setFees({
        visa: {
          spain: {
            average: 0.35
          },
          eu: {
            average: 0.4
          },
          oeu: {
            average: 2.5
          }
        },
        mastercard: {
          spain: {
            average: 0.4
          },
          eu: {
            average: 0.58
          },
          oeu: {
            average: 2.15
          }
        }
      });
    }
  }, [amount]);

  useEffect(() => {
    if (!amount) return;
    const stripeFee: any = {percentage: 1.5, base: 0.25};

    setCalculatedFees({
      visaMonei: {
        interchange: ((fees.visa[region as keyof TerritorialFee].average + 0.03) / 100) * amount,
        provider: (moneiFee.percentage / 100) * amount + moneiFee.base,
        percentage: moneiFee.percentage,
        base: moneiFee.base,
        providerName: 'MONEI'
      },
      mastercardMonei: {
        interchange:
          ((fees.mastercard[region as keyof TerritorialFee].average + 0.03) / 100) * amount,
        provider: (moneiFee.percentage / 100) * amount + moneiFee.base,
        percentage: moneiFee.percentage,
        base: moneiFee.base,
        providerName: 'MONEI'
      },
      visaStripe: {
        interchange: ((fees.visa[region as keyof TerritorialFee].average - 0.1) / 100) * amount,
        provider: (stripeFee.percentage / 100) * amount + stripeFee.base,
        percentage: stripeFee.percentage,
        base: stripeFee.base,
        providerName: 'Stripe'
      },
      mastercardStripe: {
        interchange:
          ((fees.mastercard[region as keyof TerritorialFee].average - 0.1) / 100) * amount,
        provider: (stripeFee.percentage / 100) * amount + stripeFee.base,
        percentage: stripeFee.percentage,
        base: stripeFee.base,
        providerName: 'Stripe'
      }
    });
  }, [fees, moneiFee, region]);

  useEffect(() => {
    setStripeVisaPercentMore(
      (
        ((calculatedFees.visaStripe.provider + calculatedFees.visaStripe.interchange) /
          (calculatedFees.visaMonei.provider + calculatedFees.visaMonei.interchange) -
          1) *
        100
      ).toFixed(0)
    );
    setStripeMastercardPercentMore(
      (
        ((calculatedFees.mastercardStripe.provider + calculatedFees.mastercardStripe.interchange) /
          (calculatedFees.mastercardMonei.provider + calculatedFees.mastercardMonei.interchange) -
          1) *
        100
      ).toFixed(0)
    );
  }, [calculatedFees]);

  const withCurrency = (value: string) => {
    const afterPrice = ['es', 'ca', 'fr', 'it', 'pt', 'de'].includes(language);
    return (!afterPrice ? '€ ' : '') + value + (afterPrice ? ' €' : '');
  };

  const faqs = [
    {
      header: t('What are interchange fees?'),
      text: t(
        'Interchange fees are also referred to as interchange rates or reimbursement fees. They are the fees you must pay to card-issuing banks each time a customer completes a credit or debit card purchase from your stores.'
      ),
      content: (
        <Trans>
          <BlogLink slug="what-are-interchange-fees">Interchange fees</BlogLink> are also referred
          to as interchange rates or reimbursement fees. They are the fees you must pay to
          card-issuing banks each time a customer completes a credit or debit card purchase from
          your stores.
        </Trans>
      )
    },
    {
      header: t('Why do I have to pay interchange fees?'),
      text: t(
        'Many players are involved in credit card payment processing before the transaction is approved and funds are transferred to your business bank account. When a shopper makes a card purchase, your business, your bank (acquirer), and the customer’s bank (issuer) are all involved in the transaction.'
      ),
      content: (
        <Trans>
          Many players are involved in credit{' '}
          <BlogLink slug="what-is-credit-card-processing">card payment processing</BlogLink> before
          the transaction is approved and funds are transferred to your business bank account. When
          a shopper makes a card purchase, your business,{' '}
          <BlogLink slug="acquiring-bank-vs-issuing-bank">
            your bank (acquirer), and the customer’s bank (issuer)
          </BlogLink>{' '}
          are all involved in the transaction. When the issuing bank sends the payment to the
          acquiring bank, there’s a small fee — also known as the interchange fee. Without
          interchange rates, issuing banks wouldn’t be able to cover the costs like maintenance,
          customer service, and fraud prevention to operate card services.{' '}
        </Trans>
      )
    },
    {
      header: t('How is interchange used?'),
      text: t(
        'Interchange fees help payment systems work quickly and securely, cover card service operating costs that would otherwise be passed from banks onto consumers, and contribute to innovation investments and system maintenance.'
      ),
      content: (
        <Trans>
          Interchange fees help payment systems work quickly and securely, cover card service
          operating costs that would otherwise be passed from banks onto consumers, and contribute
          to innovation investments and system maintenance so card networks like Visa and Mastercard
          can provide a faster, more convenient, and secure payment experience.
        </Trans>
      )
    },
    {
      header: t('Does each card network have different interchange rates?'),
      text: t(
        'Yes. Interchange fees are determined by card networks and are adjusted regularly. Take a look at Visa Interchange++, Mastercard Interchange++, and learn more about Interchange++ pricing.'
      ),
      content: (
        <Trans>
          Yes. Interchange fees are{' '}
          <AnchorLink href="https://support.monei.com/hc/en-us/articles/4416503871889-Credit-card-interchange-fees">
            determined by card networks
          </AnchorLink>{' '}
          and are adjusted regularly. Take a look at{' '}
          <AnchorLink href="https://www.visa.co.uk/about-visa/visa-in-europe/fees-and-interchange.html">
            Visa Interchange++
          </AnchorLink>
          ,{' '}
          <AnchorLink href="https://www.mastercard.co.uk/content/dam/public/mastercardcom/eu/gb/Other/Website</Trans>_Intra_EEA_Fallback_Interchange_Fees_220121.pdf">
            Mastercard Interchange++
          </AnchorLink>
          , and learn more about{' '}
          <AnchorLink href="https://support.monei.com/hc/en-us/articles/4416520191249-What-is-Interchange-pricing-and-how-does-it-work-">
            Interchange++ pricing
          </AnchorLink>
          .{' '}
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        path="interchange-fee-calculator"
        title={t('Free Interchange Fee Calculator')}
        description={t(
          'Wondering how to calculate interchange fees? Save time with this free interchange fee' +
            ' calculator.'
        )}
      />
      <IndexBackground>
        <Content>
          <Section columnMd style={{alignItems: 'center'}}>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Interchange fee calculator</Trans>
              </SectionHeader>
              <Trans parent="p">
                Not sure how to calculate interchange fees? You’ve come to the right place. Enter a
                purchase amount to calculate credit and debit card interchange fees.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Save on Card Payments Now</Trans>
                </SignUpButton>
              </SectionActions>
            </IndexContent>

            <CalculatorCard>
              <CalculatorForm>
                <Flex className="form-container" direction="column">
                  <DataRow
                    style={{
                      marginBottom: '12px'
                    }}>
                    <Trans parent="span" style={{flex: 3}}>
                      Enter a payment amount to calculate interchange fees
                    </Trans>
                    <InputWrapper style={{}}>
                      <CurrencyInput
                        onValueChange={(value: number) => {
                          setAmount(value);
                        }}
                      />
                    </InputWrapper>
                  </DataRow>
                  <DataRow
                    style={{
                      marginBottom: '18px'
                    }}>
                    <Trans parent="span">Estimated monthly billing volume</Trans>
                    <InputWrapper>
                      <Select
                        onChange={(val) =>
                          setMoneiFee({percentage: Number(val.target.value), base: 0.24})
                        }>
                        <option value={1.19}>{withCurrency('0 - 200K')}</option>
                        <option value={0.9} selected>
                          {withCurrency('200K - 500K')}
                        </option>
                        <option value={0.6}>{withCurrency('0,5M - 1M')}</option>
                        <option value={0.4}>{withCurrency('1M - 3M')}</option>
                        <option value={0.3}>{withCurrency('3M - 5M')}</option>
                        <option value={0.25}>{withCurrency('5M - 7M')}</option>
                        <option value={0.2}>{withCurrency('7M - 10M')}</option>
                        <option value={0.15}>{withCurrency('10M +')}</option>
                      </Select>
                    </InputWrapper>
                  </DataRow>
                  <DataRow>
                    <Trans parent="span">Customers region</Trans>
                    <InputWrapper>
                      <Select onChange={(val) => setRegion(val.target.value)}>
                        <option value={'spain'}>Spain</option>
                        <option value={'eu'}>EU</option>
                        <option value={'oeu'}>Outside EU</option>
                      </Select>
                    </InputWrapper>
                  </DataRow>
                </Flex>
              </CalculatorForm>
              <Divider style={{margin: '16px 0'}} />
              {calculatedFees.visaMonei.interchange > 0 && (
                <List>
                  <ListItem>
                    <Trans parent="p">With Visa & MONEI you pay:</Trans>
                    <Hint
                      id="visa_monei"
                      title={
                        <Bold>
                          {currency(language).format(
                            calculatedFees.visaMonei.interchange + calculatedFees.visaMonei.provider
                          )}
                        </Bold>
                      }
                      message={FeeTooltip(calculatedFees.visaMonei)}
                    />
                  </ListItem>
                  <ListItem>
                    <Trans parent="p">With Mastercard & MONEI you pay:</Trans>
                    <Hint
                      id="mastercard_monei"
                      title={
                        <Bold>
                          {currency(language).format(
                            calculatedFees.mastercardMonei.interchange +
                              calculatedFees.mastercardMonei.provider
                          )}
                        </Bold>
                      }
                      message={FeeTooltip(calculatedFees.mastercardMonei)}
                    />
                  </ListItem>
                  <ListItem>
                    <p>
                      <Trans>With Visa & Stripe you pay:</Trans>
                      <br />
                      <Bold>
                        {stripeVisaPercentMore}% <Trans>more</Trans>
                      </Bold>
                    </p>
                    <Hint
                      id="visa_stripe"
                      title={
                        <Bold>
                          {currency(language).format(
                            calculatedFees.visaStripe.interchange +
                              calculatedFees.visaStripe.provider
                          )}
                        </Bold>
                      }
                      message={FeeTooltip(calculatedFees.visaStripe)}
                    />
                  </ListItem>
                  <ListItem>
                    <p>
                      <Trans>With Mastercard & Stripe you pay</Trans>
                      <br />

                      <Bold>
                        {stripeMastercardPercentMore}% <Trans>more</Trans>
                      </Bold>
                    </p>
                    <Hint
                      id="mastercard_stripe"
                      title={
                        <Bold>
                          {currency(language).format(
                            calculatedFees.mastercardStripe.interchange +
                              calculatedFees.mastercardStripe.provider
                          )}
                        </Bold>
                      }
                      message={FeeTooltip(calculatedFees.mastercardStripe)}
                    />
                  </ListItem>
                </List>
              )}
              <SmallText>
                <Trans>
                  This interchange fee calculator is intended for illustrative purposes. Rates are
                  regularly adjusted by card networks and recent changes may not always be reflected
                  here.
                </Trans>
              </SmallText>
            </CalculatorCard>
          </Section>
        </Content>
      </IndexBackground>
      <Container>
        <RocketContent>
          <Cta>
            <CtaWrapper>
              <CtaContent>
                <SectionTitle style={{color: 'white', borderBottom: '1ps solid white'}}>
                  <Trans>
                    Ready to accept card payments
                    <Spacer /> (and more)?
                  </Trans>
                </SectionTitle>
                <SectionDescription>
                  <Trans parent="p">
                    Boost customer satisfaction and sales by accepting more payment methods, while
                    spending less on transaction fees{' '}
                    <PrismicPage slug="compare-payment-gateways">
                      compared to other payment gateways
                    </PrismicPage>
                    .
                  </Trans>
                  <Trans parent="p">
                    Join MONEI with no commitment to test payments and integrations.
                  </Trans>
                </SectionDescription>
                <SectionActions>
                  <SignUpButton variant="dark">
                    {' '}
                    <Trans>Open an Account</Trans>
                  </SignUpButton>
                </SectionActions>
              </CtaContent>
              <Rocket src={rocket} width={250} />
            </CtaWrapper>
          </Cta>
        </RocketContent>
      </Container>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Interchange fee FAQs</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default InterchangeFeesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "interchange-fee-calculator"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
